<template>
    <div class="mbanc-login">
        <div class="container-fluid">
            <notifications></notifications>
            <div class="login-log">
                <div class="loginMain">
                    <form>
                        <card class="card-login card-white">
                            <img src="img/textMbanc.png" alt="" />
                            <!-- <template slot="header">
                                <h1>Login To Your Account</h1>
                            </template> -->

                            <div class="LoginInputs">
                                <label>Email</label>
                                <div class="loginFlex">
                                    <div class="loginSvg">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="email" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background: new 0 0 32 32" xml:space="preserve">
                                            <g>
                                                <g>
                                                    <path class="st0" d="M29.9,6.2v19.6H2.1V6.2H29.9 M30.1,4.2H1.9c-1,0-1.8,0.8-1.8,1.8v19.9c0,1,0.8,1.8,1.8,1.8h28.2    c1,0,1.8-0.8,1.8-1.8V6.1C31.9,5.1,31.1,4.2,30.1,4.2L30.1,4.2z" />
                                                </g>
                                                <path class="st1" d="M1.5,5.5L14,16.6c1.2,1.1,3.1,1.1,4.3,0L30.8,5.5" />
                                            </g>
                                        </svg>
                                    </div>
                                    <base-input v-validate="modelValidations.email" name="email" :error="getError('email')" v-model="model.email" placeholder="Email" type="email"> </base-input>
                                </div>
                                <label>Passcode</label>
                                <div class="loginFlex">
                                    <div class="loginSvg">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="password" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background: new 0 0 32 32" xml:space="preserve">
                                            <g>
                                                <circle class="st0" cx="23.9" cy="8.5" r="7.1" />
                                                <line class="st0" x1="18.5" y1="13.1" x2="1.1" y2="30.6" />
                                                <line class="st0" x1="9" y1="22.7" x2="11.8" y2="25.5" />
                                                <line class="st0" x1="4.5" y1="27.2" x2="7.8" y2="30.5" />
                                            </g>
                                        </svg>
                                    </div>
                                    <base-input v-validate="modelValidations.password" name="password" :error="getError('password')" v-model="model.password" type="password" placeholder="Password"> </base-input>
                                </div>
                            </div>

                            <div slot="footer">
                                <div class="loginButtonSvg">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="logout" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background: new 0 0 32 32" xml:space="preserve">
                                        <path class="st0" d="M16,30.9H4.2c-1.7,0-3.1-1.4-3.1-3.1V4.2c0-1.7,1.4-3.1,3.1-3.1H16" />
                                        <polyline class="st0" points="23.3,8.7 30.9,16.2 23.3,23.8 " />
                                        <line class="st0" x1="30.9" y1="16.2" x2="12.2" y2="16.2" /></svg
                                    ><base-button native-type="submit" @click.prevent="login" type="primary" class="mb-3" style="background-color: #f16925 !important; background-image: none !important" size="lg" block> Login </base-button>
                                </div>
                                <!-- <p>or</p> -->

                                <!-- <div class="pull-left">
                                    <h6>
                                        <router-link class="link footer-link" to="/register" style="color: rgb(241, 105, 37)"> Create Account </router-link>
                                    </h6>
                                </div> -->
                            </div>
                        </card>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { RingLoader } from "vue-spinners-css";
export default {
    data() {
        return {
            loading: true,
            model: {
                email: "",
                password: "",
                subscribe: true,
            },
            modelValidations: {
                email: {
                    email: true,
                    required: true,
                },
                password: {
                    required: true,
                    min: 6,
                },
            },
        };
    },
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        login() {
            this.$validator.validateAll().then((isValid) => {
                if (isValid) {
                    this.$insProgress.start();
                    this.$store
                        .dispatch("api/authin", this.model)
                        .then((response) => {
                            //console.log(response);
                            this.$cookie.set("auth", JSON.stringify(this.$store.getters["api/auth"]));
                            this.$notify({
                                timeout: 2000,
                                verticalAlign: "top",
                                horizontalAlign: "right",
                                message: "Login Successfully",
                                icon: "tim-icons icon-check-2",
                                type: "success",
                            });
                            this.$router.push("/dashboard");
                            this.$insProgress.finish();
                        })
                        .catch((res) => {
                            //console.log(res.response);
                            this.$insProgress.finish();
                            this.$notify({
                                timeout: 2000,
                                verticalAlign: "top",
                                horizontalAlign: "right",
                                message: "Your credentials does not matched to our records!",
                                icon: "tim-icons icon-alert-circle-exc",
                                type: "warning",
                            });
                        });
                }
            });
        },
        /*
             this.axios.post("http://127.0.0.1:8000/api/authin",this.model)
             .then(response => {

             });
             */
    },
};
</script>

<style>
</style>
