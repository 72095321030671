<template>
<div class="mbanc-register">
<img class="main-register-img" src="img/register-bg.png" alt="">
    <div class="container ">
        <notifications></notifications>
		<div class="text-center mb-5"><img src="img/mbanc-new-logo.png" alt=""></div>

            <div class="">
                <form>
                    <card class="card-register card-white">

                        <template slot="header" class="pb-0">
                            
                        <h1 class="text-dark font-weight-600 display-3">Register Now</h1>
                   
                        </template>
                        <div class="row">
						<div class="col-lg-5 mb-2">
                            <base-input v-validate="modelValidations.name" :error="getError('name')" v-model="model.name" name="name" type="text" placeholder="Full Name" addon-left-icon="tim-icons icon-single-02">
                            </base-input>
							</div>
							<div class="col-sm-5 mb-2">
                            <!-- <base-input v-validate="modelValidations.username" :error="getError('username')" v-model="model.username" name="username" type="text" placeholder="User name" addon-left-icon="tim-icons icon-single-02"> -->
                            <!-- </base-input> -->
							 <base-input v-validate="modelValidations.email" :error="getError('email')" v-model="model.email" name="email" placeholder="Email" type="email" addon-left-icon="tim-icons-85">
                            </base-input>
</div>
<div class="col-sm-5">
                           <base-input v-validate="modelValidations.password" :error="getError('password')" v-model="model.password" name="password" type="password" placeholder="Password" addon-left-icon="tim-icons-key">
                            </base-input>
							</div>
<div class="col-sm-5">
<base-input v-validate="modelValidations.c_password" :error="getError('c_password')" v-model="model.c_password" name="c_password" type="password" placeholder="Confirm_Password" addon-left-icon="tim-icons-key">
                            </base-input>
                            
							</div>
							
                        </div>
                        <div slot="footer" class="col-sm-4 center pt-0">
                            <base-button native-type="submit" @click.prevent="register" type="primary" class="rounded-pill mb-3" style="background-color: #f16925 !important;background-image: none !important;" size="lg" block>
                                Register
                            </base-button>
							<p>or</p>
                            <div class="pull-left w-100">
                                <h6>
                                    <router-link class="rounded-pill link footer-link" to="/login" style="
    color: rgb(241, 105, 37);">
                                        Login Account
                                    </router-link>
                                </h6>
                            </div>
                        </div>
                    </card>
                </form>
            </div>
    </div>
	<img class="main-register-secimg" src="img/register-bg-img.png" alt="">
</div>
</template>

<script>
import {
    BaseCheckbox
} from 'src/components';

export default {
    components: {
        BaseCheckbox
    },
    data() {
        return {
            model: {
                name: '',
                username: '',
                email: '',
                password: '',
                c_password: ''
            },
            modelValidations: {
                email: {
                    email: true,
                    required: true
                },
                password: {
                    required: true,
                    min: 6
                },
                c_password: {
                    required: true
                },
                name: {
                    required: true
                },
                username: {
                    required: true
                }
            }
        };
    },
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        async register() {
            this.$validator.validateAll().then(isValid => {
                if (isValid) {
                    this.$insProgress.start();
                    this.$store.dispatch("api/authup", this.model).then((response) => {
                        this.$cookie.set("auth", JSON.stringify(this.$store.getters['api/auth']));
                        this.$notify({
                            timeout: 2000,
                            verticalAlign: 'top',
                            horizontalAlign: 'right',
                            message: 'You Have Registered Successfully',
                            icon: 'tim-icons icon-check-2',
                            type: 'success',
                        });
                        this.$router.push('/dashboard');
                        this.$insProgress.finish();

                    }).catch((res) => {
                        //console.log(res.response);
                        this.$insProgress.finish();
                        this.$notify({
                            timeout: 2000,
                            verticalAlign: 'top',
                            horizontalAlign: 'right',
                            message: 'The data you have passed has been failed in validation!',
                            icon: 'tim-icons icon-alert-circle-exc',
                            type: 'warning',
                        });

                    });
                }
            })

        },
    }
};
</script>

<style>
.card-register .card-footer {
    margin-top: 0px;
}
</style>
