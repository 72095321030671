<template>
<div class="row">

    <div class="col-md-8">
        <card>
            <h5 slot="header" class="title"> Profile</h5>
            <form @submit.prevent="updateProfile">
                <div class="row">

                    <div class="col-md-6">
                        <base-input type="text" label="Name" placeholder="Name" v-model="user.name">
                        </base-input>
                    </div>
                    <div class="col-md-6">
                        <base-input type="text" label="User Name" placeholder="User Name" v-model="user.username">
                        </base-input>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-6">
                        <base-input readonly type="email" label="Email" placeholder="Email" v-model="user.email">
                        </base-input>
                    </div>
                    <div class="col-md-6">
                       <label>Assigned Number</label>
                <el-select class="select-primary" v-if="user.id < 3"  size="large" placeholder="Select" v-model="user.twillio">
                    <el-option v-for="option in t_numbers.numbers" class="select-primary" :value="option.number" :label="option.number" :key="option.number">
                    </el-option>
                </el-select>
                <base-input v-if="user.id > 3" readonly type="text" placeholder="Assigned Number" v-model="user.twillio" ></base-input>
                    </div>
                </div>

                <div class="row">
                <div class="col-md-6">
                        <base-input type="password" label="Password" placeholder="Password" v-model="user.password">
                        </base-input>
                    </div>
                <div class="col-md-6">
                        <base-input type="text" label="Phone Number" placeholder="Phone Number" v-model="user.phone">
                        </base-input>
                    </div>
                </div>
                <div class="row">
                <div class="col-md-12">
                        <base-input type="text" label="Address" placeholder="Home Address" v-model="user.address">
                        </base-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <base-input type="text" label="City" placeholder="City" v-model="user.city">
                        </base-input>
                    </div>
                    <div class="col-md-4">
                        <base-input type="text" label="Country" placeholder="Country" v-model="user.country">
                        </base-input>
                    </div>
                    <div class="col-md-4">
                        <base-input label="Postal Code" placeholder="ZIP Code" v-model="user.zipcode">
                        </base-input>
                    </div>
                </div>
                <base-button native-type="submit" type="success" class="btn-fill">
                    Update
                </base-button>
                <base-button type="default" class="btn-fill" @click="$router.go(-1)">
                    Back
                </base-button>
            </form>
        </card>
    </div>
    <div class="col-md-4">
        <card class="card-user">
            <p class="card-text"></p>
            <div class="author">
                <div class="block block-one"></div>
                <div class="block block-two"></div>
                <div class="block block-three"></div>
                <div class="block block-four"></div>
                <a href="javascript:void(0)">
                    <img class="avatar" src="img/emilyz.jpg" alt="..." />
                </a>
                <h5 class="title">{{user.name}}</h5>
            </div>
        </card>
    </div>
</div>
</template>

<script>
import {
    Select,
    Option
} from 'element-ui';
export default {
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
    },
    data() {
        return {
            user: {},
            t_numbers: {
                simple: '',
                numbers: [],
            },
        };
    },
    methods: {
        updateProfile() {
            this.$insProgress.start();
            //console.log("UPDATING USER PROFILE...");
            if(this.user.id > 3){
                this.user.twillio = '';
            }
            this.$store.dispatch("user/update", this.user).then((response) => {
                if (response.data.code === 200) {
                    //console.log("USER PROFILE UPDATED!");
                    //console.log("UPDATING CACHE...");
                    var auth = JSON.parse(this.$cookie.get("auth"));
                    //console.log(auth);
                    //console.log(this.user);
                    auth.user = this.user;
                    this.$cookie.set("auth", JSON.stringify(auth));
                    //console.log("CACHE UPDATED!");
                    this.$router.go(-1);
                    this.$insProgress.finish();
                    this.$notify({
                        timeout: 2000,
                        verticalAlign: 'top',
                        horizontalAlign: 'right',
                        message: 'Your Profile Updated successfully!',
                        icon: 'tim-icons icon-check-2',
                        type: 'success',
                    });
                }
            });
        },
        ViewProfile() {
            this.$store.dispatch("user/fetch").then((response) => {

                var data = response.data.data.user;
                //console.log("FETCHING USER PROFILE...");
                //console.log(data);
                var user = {};
                user.id = data.id;
                user.email = data.email;
                user.username = data.username;
                user.phone = data.phone;
                user.name = data.name;
                user.address = data.address;
                user.city = data.city;
                user.country = data.country;
                user.zipcode = data.zipcode;
                user.twillio = data.twillio;
                //console.log(user);
                this.user = user;
                //console.log(this.user);
                this.$insProgress.finish();
            });
        },
        
        twillio_nbr_fetch() {
            this.$insProgress.start();
            this.$store.dispatch("general/twillio_numbers").then((response) => {
                //console.log(response);
                this.t_numbers.numbers = response.data.data;
                this.$insProgress.finish();
            }).catch((e) => {
                this.$insProgress.finish();
            });
        },
    },
    created() {
        this.$insProgress.start();
        this.ViewProfile();
        this.twillio_nbr_fetch();
    }
};
</script>



<style>
.el-input__prefix, .el-input__suffix{
    top:0 !important;
}

.el-select.select-primary.el-select--large{
    width:100%;
}
.el-select.select-primary.el-select--large input{
        color: #222a42;
    border-color: rgba(29, 37, 59, 0.5);
    border-radius: 0.4285rem;
}
.el-select.select-primary.el-select--large input:hover{
        color: #222a42;
    border-color: rgba(29, 37, 59, 0.5) !important;
}
</style>
